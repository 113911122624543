@import "../../typography.css";
@import "../../mixins.css";
@import "../../colors.css";

.component {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  :global .image-gallery-slide-wrapper {
    height: auto;
    min-height: 375px;
    @media (max-width: 375px) {
      min-height: 100vw;
    }
  }
}

.componentUnsetHeight {
  @media (--smartphone) {
    height: 100%;
  }
}

.imagesWrapper {
  height: auto;
  min-height: auto;
}
.modalImage {
  max-width: 350px;
  margin: $spacingSm $spacingMd;
  @media (--smartphone) {
    margin-top: $spacingHuge;
  }
}

.viewDetails {
  @mixin accessible_button;
  @mixin captionDescriptionText;
  @mixin text_primary;
  cursor: pointer;
  text-decoration: underline;
}

.finalSale {
  @mixin captionDescriptionText;
  .finalSaleText {
    @mixin text_error;
  }
}

.summary {
  margin-bottom: 0;
}

.onlyXLeft, .soldOut {
  text-align: center;
  margin-bottom: 10px;
}

.summaryDetailsButtons {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.summaryDetailsIsModal {
  @media (--tablet-and-desktop) {
    flex-direction: row;
  }
}

.buttonWrapper {
  position:relative;
  box-shadow: 2px -3px 5px 0 $grey3;
  padding: 12px;
}

.noButton {
  position:relative;
  box-shadow: none;
  padding: 0;
}

.contentWrapper {
  padding: $spacingSm $spacingMd;
  flex-direction: column;
}

.modalContentWidth {
  @media (--tablet-and-desktop) {
    padding-right: $spacingXl;
    overflow: auto;
    max-height: 557px;
  }
}

.errorMessage {
  @mixin bodyText;
  @mixin text_error;
  text-align: center;
}

.errorMessageDisplay {
  @mixin bodyText;
  @mixin text_primary;
  text-align: center;
}

.successMessage {
  @mixin bodyText;
  @mixin text_success;
  text-align: center;
}