@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";
@import "../../colors.css";

$fullPageCartMaxWidth: env(--fullPageCartMaxWidth);

.component {
  @mixin background_secondary;
  @mixin text_primary;
  @mixin bodyTitleText;
  padding: $spacingXs;
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: scroll;

  &.cart_full_page {
    /* Matching min height and max width of page container in with_app_layout */
    min-height: 90vh;
    padding-top: $spacingXl;
    padding-left: $spacingLg;
    padding-right: $spacingLg;

    .cartFooterWrapper {
      margin-bottom: $spacingXs;
    }

    .contentWrapper, .cartFooterWrapper {
      max-width: $(fullPageCartMaxWidth)px;

      @media (--tablet-and-desktop) {
        margin: auto;
      }
    }

    @media (--tablet-and-desktop) {
      .cartFooterWrapper {
        margin-bottom: $spacingXs;
      }

      .cartFooterWrapper > div {
        padding: 0;
      }

      .contentWrapper {
        grid-template-columns: minmax(50%, 1fr) auto;
        gap: $spacingXs;
      }
    }
  }

  .notifications, .recommenedItems, .cartLeftColumn {
    margin-bottom: $spacingSm;
  }

  .cartFooterWrapper > div {
    padding: $spacingXs;
  }

  .contentWrapper {
    display: grid;
    grid-template-columns: minmax(50%, 1fr);
    margin-bottom: $spacingXs;
  }
}

.emptyEnter {
  opacity: 0;
}

.mobileCheckoutButton {
  margin-bottom: $spacingXs;

  @media (--tablet-and-desktop) {
    display: none;
  }
}

.mobileCheckoutButtonSticky {
  @media (--smartphone-and-tablet) {
    position: fixed;
    bottom: 0;
    z-index: 2;
    left: 0;
    width: 100%;
    padding: 1rem 2rem 0.5rem;
    background-color: $white1;
    box-shadow: 0px -1px 3px $grey3;
  }
}

.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .progressBarContainer {
    width:80%;
  }

  .number {
    padding: 0 $spacingNudge;
    @mixin metaTitleText;
  }

  .progressBar {
    @mixin background_secondary;
    border-radius: 100px;

    .progress {
      background: $burgandy;
      border-radius: 100px;
      height:7px;
      transition-delay:0s;
      transition-duration:0.3s;
      transition-property:width;
      transition-timing-function:ease-in-out;
      max-width: 100%;
    }
  }
}

.progressText {
  @mixin metaTitleText;
  text-align: center;
  margin-bottom: $spacingNudge;
}

.legalText {
  @mixin captionTitleText;
  text-align: center;
  @mixin text_secondary;
  width: 100%;
  display: block;
  padding-bottom: $spacingXs;
  span {
    text-decoration: underline;
  }
}


.emptyEnterActive {
  opacity: 1;
  transition: opacity 300ms linear;
}

.lineItemExit {
  opacity: 1;
}

.lineItemExitActive {
  opacity: 0;
  transition: opacity 300ms linear;
}

.lineItems {
  overflow-touch: auto;
  padding: 0;
  flex: 1;
  list-style: none;
  min-height: 150px;
  overflow-y: auto;
  &.cart_double_width {
    @media (--large-tablet-and-desktop) {
      display: inline-block;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.lineItemsList {
  list-style: none;
}

.cardWrapper {
  @media (--smartphone) {
    margin-bottom: $spacingXs;
    padding: 0 $spacingSm;
  }
  &.cart_full_page {
    @media (--large-tablet-and-desktop) {
      position: sticky;
      position: -webkit-sticky; /* Safari */
      top: 0;
    }
  }
}

.card {
  @mixin background_primary;
    padding: $spacingSm;
    margin-bottom: $spacingSm;
    border-radius: $spacingNudge;

  &.max_width {
    @media (--large-tablet-and-desktop) {
      max-width: 395px;
    }
  }
}

.cardTitle {
  margin: 0 0 $spacingSm;
  @mixin headerText;
  @mixin text_primary;
  @media (--smartphone) {
    display: none;
  }
}

.empty {
  padding: $spacingSm $spacingMd;
  @mixin flex_center;
  width: 100%;
  text-align: center;
  flex-direction: column;
  &.cart_full_page {
    @media (--large-tablet-and-desktop) {
      margin-right: calc(-1 * $spacingXs);
    }
  }
}

.emptyCartFooter {
  background-color: $white1;
  padding: $spacingSm;
  margin-bottom: $spacingSm;

  &.cart_full_page {
    @media (--large-tablet-and-desktop) {
      padding: $spacingXs;
    }
  }
}

.emptyCta {
  margin-bottom: $spacingXs;
  width: 200px;
  @mixin background_primary;
  font-family: "Spezia Medium";
  font-weight: 500;
  font-size: 15px;
  letter-spacing: normal;
  text-transform: capitalize;
}

.emptyText {
  @mixin titleText;
  font-weight: 600;
  margin-bottom: $spacingXs;
}

.emptyDescriptionText {
  @mixin paragraphText;
  margin-bottom: $spacingMd;
}

.empty.notificationPresent {
  position: static;
}

.lineItem {
  @mixin background_primary;
  min-height: 85px;
}

.lineItemLoading {
  position: relative;
  height: 50px;
  padding: $spacingSm;
}

.spinner {
  width: 60px;
  margin: 0 auto;
}

.lineItemLoadingEmpty {
  margin-top: 120px;
}

.footer {
  margin-bottom: $spacingXs;
  margin-left: 0;

  @media (--tablet-and-desktop) {
    &.cart_double_width, &.cart_full_page {
      margin-bottom: $spacingSm;
      vertical-align: top;
      margin-left: $spacingXs;
    }
  }

  @media (--smartphone) {
    border-top: none;
    @mixin background_primary;
    padding-bottom: $spacingHuge;
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-bottom: $spacingXs;
    @mixin metaDescriptionText;

    &.total {
      @mixin subheaderText;
      @mixin text_primary;
      padding-top: $spacingXs;
      padding-bottom: $spacingMd;
    }
  }
}

.footerItem {
  padding-top: $spacingSm;
}

.guestCheckoutOptions {
  padding: $spacingXs 0px $spacingNudge 0px;

  span, h4, a {
    display: block;
    text-align: center;
  }
  h4 {
    @mixin metaDescriptionText;
    font-size: 16px;
    font-weight: 700;
    margin: $spacingNudge 0px 0px 0px;
  }
  span {
    @mixin captionDescriptionText;
    font-size: 14px;
  }

  .guestCheckoutLink {
    @mixin captionDescriptionText;
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 1em;
  }
}

.shipping {
  dd {
    text-transform: uppercase;
  }
}

.promoCodeForm form {
  padding-bottom: $spacingSm;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.checkoutForm {
  display: flex;
}

.subtotal {
}

.markdown {
}

.appliedPromo {
}

.bundleDiscount {
}

.storeCredit {
}

.shippingMessage{
  @mixin metaDescriptionText;
  @mixin text_secondary;
}

.affirm {
  text-align: center;
}

.desktopCheckoutButton {
  display: none;

  @media (--desktop) {
    display: block;
  } 
}
