@import "../../media.css";

.component {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;

  .slideWrapper {
    height: 428px;
    min-height: auto;
    width: 285px;
    margin-right: 4px;
    flex: 0 0 auto;
  }
  .slideWrapperModalImage {
    min-height: auto;
    margin-right: 4px;
    flex: 0 0 auto;
  }
}
