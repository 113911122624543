@import "../typography.css";
@import "../mixins.css";

.component {
  @mixin metaDescriptionText;
  overflow: hidden;
  text-align: left;
  position: relative;
  width: 100%;
  height: 32px;
}

.label {
  position: absolute;
  display: flex;
  align-items: center;
}

.input {
  opacity: 0;
  width: $spacingMd;
  height: 18px;
}

[data-keyboard="true"] {
  .input:focus + div {
    @mixin default_ada_focus;
  }
}

.checkbox {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
}

.text {
  @mixin text_primary;
}
