@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.affirmEstimate {
  @mixin metaTitleText;
  @mixin text_primary;
  padding: unset;
  margin-top: $spacingXs;
  margin-bottom: $spacingNudge;
  border: none;
  background-color: transparent;
  text-align: center;
  pointer-events: none;

  @media (--smartphone) {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-top: $spacingSm;
  }

  a {
    text-decoration: underline;
    background-color: transparent;
    border: none;
    color: black;
    pointer-events: auto;
  }
}
