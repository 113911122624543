@import "../mixins.css";
@import "../colors.css";

.component {
  width: 100%;
  height: 100%;
  svg {
    font-size: 30px;
  }
}

.applePayButton {
  margin-top: $spacingXs;
}

.icon {
  font-size: $iconSize4x !important;
}
